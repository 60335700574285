window.addEventListener("DOMContentLoaded", () => {
    // swiper reviews
    try {
        const swiperReviews = new Swiper('.swiper-reviews', {
            loop: false,
            speed: 400,
            spaceBetween: 16,
            slidesPerView: 1,
            parallax: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                },
                1024: {
                    slidesPerView: 3,
                }
            }
        });
    } catch (e) {
        console.log(e)
    }

    // swiper drawning
    try {
        const swiperDrawing = new Swiper('.swiper-drawing', {
            loop: false,
            speed: 400,
            spaceBetween: 16,
            slidesPerView: 1,
            parallax: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                },
                1024: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                }
            }
        });
    } catch (e) {
        console.log(e)
    }

    // swiper drawning
    try {
        const swiperDrawing = new Swiper('.swiper-gallery', {
            loop: false,
            speed: 400,
            spaceBetween: 16,
            slidesPerView: 1,
            parallax: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                },
                1024: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                }
            }
        });
    } catch (e) {
        console.log(e)
    }

    //scroll
    try {

        const header = document.querySelector("#header");

        window.addEventListener('scroll', () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 52) {
                header.classList.add("scroll");
            } else {
                header.classList.remove("scroll");
            }

        });
    } catch (e) {
        console.log(e)
    }

    //burger
    try {
        const burger = document.querySelector("#burger");
        const navigation = document.querySelector("#navigation");

        burger.addEventListener("click", (e) => {
            e.preventDefault();
            burger.classList.toggle("open");
            navigation.classList.toggle("open");
            document.querySelector("body").classList.toggle("overflow-hidden");
            //navs.classList.toggle("open");
        })


        // let anchors = document.querySelectorAll(".anchor");
        // for(let anchor of anchors){
        //     anchor.addEventListener("click", (e) => {
        //         e.preventDefault();
        //         const blockId = anchor.getAttribute("href").substr(1);
        //         document.getElementById(blockId).scrollIntoView({
        //             "behavior": "smooth",
        //             "block": "start"
        //         });
        //         burger.classList.remove("open");
        //         navMenu.classList.remove("open");
        //         navs.classList.remove("open");
        //     });
        // }
    } catch (e) {
        console.log(e);
    }


    //modals
    try {
        const body = document.querySelector("body");

        const buttonsModal = document.querySelectorAll(".button-modal");
        buttonsModal.forEach((button) => {
            button.addEventListener("click", (e) => {
                e.preventDefault();
                body.classList.add("overflow-hidden");
                document.querySelector(`#${button.dataset.target}`).classList.add("open");
                document.querySelector(`#${button.dataset.target} h2`).innerText = button.dataset.title;
            })
        })

        const modalClose = document.querySelectorAll(".modal-close");

        modalClose.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault();

                body.classList.remove("overflow-hidden");
                document.querySelector(`#${item.dataset.target}`).classList.remove("open");
            })
        });

    } catch (e) {
        console.log(e);
    }

    //превью modal-view-pdf
    try {
        const body = document.querySelector("body");
        const buttonModalViewPdf = document.querySelectorAll(".button-modal-view-pdf");
        const modalViewPdf = document.getElementById("modal-view-pdf");

        console.log(modalViewPdf);

        buttonModalViewPdf.forEach((button) => {
            button.addEventListener("click", (e) => {
                e.preventDefault();

                document.getElementById("myPDF").setAttribute("src", button.getAttribute("data-link"));

                body.classList.add("overflow-hidden");
                modalViewPdf.classList.add("open");
            })
        })

        const modalClose = document.querySelectorAll(".modal-close");

        modalClose.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault();

                body.classList.remove("overflow-hidden");
                modalViewPdf.classList.remove("open");
            })
        });


    } catch (e) {
        console.log(e);
    }


    const getFormDataToModal = (formId) => {
        const formTitle = document.querySelector(`#${formId}`).getAttribute("data-title");
        const formGruzopodenmost = document.querySelector(`#${formId}`).getAttribute("data-gruzopodenmost");
        const formKonsktrukciya = document.querySelector(`#${formId}`).getAttribute("data-konsktrukciya");
        const formProizvoditel = document.querySelector(`#${formId}`).getAttribute("data-proizvoditel");
        const formTip = document.querySelector(`#${formId}`).getAttribute("data-tip");
        const formIspolnenie = document.querySelector(`#${formId}`).getAttribute("data-ispolnenie");
        const formPrivod = document.querySelector(`#${formId}`).getAttribute("data-privod");
        const formKlimaticheskoeIspolnenie = document.querySelector(`#${formId}`).getAttribute("data-klimaticheskoeIspolnenie");

        return {
            title: formTitle,
            gruzopodenmost: formGruzopodenmost,
            konsktrukciya: formKonsktrukciya,
            proizvoditel: formProizvoditel,
            tip: formTip,
            ispolnenie: formIspolnenie,
            privod: formPrivod,
            klimaticheskoeIspolnenie: formKlimaticheskoeIspolnenie,
        };

    }

    //модальное окно согласовать комплектацию
    try {
        const body = document.querySelector("body");
        const buttonAgreeOnThePackage = document.querySelectorAll(".button-agree-on-the-package");


        buttonAgreeOnThePackage.forEach((button) => {
            button.addEventListener("click", (e) => {
                e.preventDefault();

                body.classList.add("overflow-hidden");
                document.querySelector(`#modal-agree-on-the-package`).classList.add("open");

                const formId = button.getAttribute("data-form");

                document.querySelector(`#modal-agree-on-the-package form`).setAttribute("data-form", formId);

                const dataForModal = getFormDataToModal(formId);
                //console.log(dataForModal);
                document.querySelector(`#modal-agree-on-the-package .modal-title span`).innerText = dataForModal.title;
            })
        })

    } catch (e) {
        console.log(e)
    }


    //отправка формы с данными
    try {
        const modalAgreeOnThePackage = document.querySelector("#modal-agree-on-the-package");
        const modalAgreeOnThePackageForm = document.querySelector("#modal-agree-on-the-package form");

        modalAgreeOnThePackageForm.addEventListener("submit", modalAgreeOnThePackageSend);

        async function modalAgreeOnThePackageSend(event) {
            event.preventDefault();

            const formData = new FormData(event.currentTarget);
            const data = Object.fromEntries(formData);

            const formId = modalAgreeOnThePackageForm.getAttribute("data-form");

            const dataForModal = getFormDataToModal(formId);

            const fullData = {...data, ...dataForModal}

            let response = await fetch("https://crane-service.ru/wp-content/themes/crane-service/mail-constructor.php", {
                method: "POST",
                headers: new Headers({"content-type": "application/x-www-form-urlencoded"}),
                body: new URLSearchParams(fullData).toString()
            })
            if (response.ok) {
                modalAgreeOnThePackage.classList.add("success");
                modalAgreeOnThePackageForm.reset();

                setTimeout(() => {
                    modalAgreeOnThePackage.classList.remove("success");
                }, 3000);
            } else {
                modalAgreeOnThePackage.querySelector(".modal-error").style.display = "block";
                setTimeout(() => {
                    modalAgreeOnThePackage.querySelector(".modal-error").style.display = "none";
                }, 5000)
            }

        }

    } catch (e) {
        console.log(e)
    }

    // отправка формы из модального окна большой
    try {
        const modalCalculateTheCost = document.querySelector("#modal-calculate-the-сost");
        const modalCalculateTheCostForm = document.querySelector("#modal-calculate-the-сost form");
        modalCalculateTheCostForm.addEventListener("submit", modalCalculateTheCostSend);

        async function modalCalculateTheCostSend(event) {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const data = Object.fromEntries(formData);

            const title = modalCalculateTheCost.querySelector("h2").innerText;

            const fullData = {...data, title}

            let response = await fetch("https://crane-service.ru/wp-content/themes/crane-service/mail-forms.php", {
                method: "POST",
                headers: new Headers({"content-type": "application/x-www-form-urlencoded"}),
                body: new URLSearchParams(fullData).toString()
            })
            if (response.ok) {
                modalCalculateTheCost.classList.add("success");
                modalCalculateTheCostForm.reset();

                setTimeout(() => {
                    modalCalculateTheCost.classList.remove("success");
                }, 3000);
            } else {
                modalCalculateTheCost.querySelector(".modal-error").style.display = "block";
                setTimeout(() => {
                    modalCalculateTheCost.querySelector(".modal-error").style.display = "none";
                }, 5000)
            }

        }
    } catch (e) {
        console.log(e)
    }

    // отправка формы из модального окна маленькой
    try {
        const modalBackCall = document.querySelector("#modal-back-call");
        const modalBackCallForm = document.querySelector("#modal-back-call form");
        modalBackCallForm.addEventListener("submit", modalBackCallSend);

        async function modalBackCallSend(event) {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const data = Object.fromEntries(formData);
            const title = modalBackCall.querySelector("h2").innerText;
            const fullData = {...data, title}

            let response = await fetch("https://crane-service.ru/wp-content/themes/crane-service/mail-forms.php", {
                method: "POST",
                headers: new Headers({"content-type": "application/x-www-form-urlencoded"}),
                body: new URLSearchParams(fullData).toString()
            })
            if (response.ok) {
                modalBackCall.classList.add("success");
                modalBackCallForm.reset();
                setTimeout(() => {
                    modalBackCall.classList.remove("success");
                }, 3000);
            } else {
                modalBackCall.querySelector(".modal-error").style.display = "block";
                setTimeout(() => {
                    modalBackCall.querySelector(".modal-error").style.display = "none";
                }, 5000)
            }
        }
    } catch (e) {
        console.log(e)
    }

    // отправка формы с файлами 1
    try {

        const formOne = document.getElementById("formOne");
        const formOneform = formOne.querySelector("form");
        formOneform.addEventListener("submit", formOneSend);

        async function formOneSend(event) {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const data = Object.fromEntries(formData);
            const title = formOne.querySelector("h2").innerText;
            const fullData = {...data, title, dropzone: formOneImage.files[0]}
            formData.append("title", title);
            //console.log(formData);


            let response = await fetch("https://crane-service.ru/wp-content/themes/crane-service/mail-forms.php", {
                method: "POST",
                //headers: new Headers({"content-type": "application/x-www-form-urlencoded"}),
                //body: new URLSearchParams(fullData).toString()
                body: formData
            })
            if (response.ok) {
                formOne.classList.add("success");
                formOneform.reset();
                formOnePreview.innerText = "";
                setTimeout(() => {
                    formOne.classList.remove("success");
                }, 3000);
            } else {
                formOne.classList.add("error");
                setTimeout(() => {
                    formOne.classList.remove("error");
                }, 3000)
            }
        }

        const formOneImage = document.getElementById("formOneImage");
        const formOnePreview = document.getElementById("formOnePreview");
        const formOneMessage = document.getElementById("formOneMessage");

        formOneImage.addEventListener("change", () => {
            uploadFile(formOneImage.files[0]);
        })

        function uploadFile(file) {
            if (!["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
                formOneMessage.innerText = "Разрешены файлы формата .jpg, .png, .pdf";
                setTimeout(() => {
                    formOneMessage.innerText = "";
                }, 5000)
                formOneImage.value = "";
                return;
            }
            if (file.size > 4 * 1024 * 1024) {
                formOneMessage.innerText = "Файл должен быть менее 4 Мб";
                setTimeout(() => {
                    formOneMessage.innerText = "";
                }, 5000);
                formOneImage.value = "";
                return;
            }

            let reader = new FileReader();
            reader.onload = function (e) {
                //formOnePreview.innerText = file.name;
                //formOnePreview.innerHTML = `<img src="${e.target.result}" alt="foto">`;
            }
            reader.onerror = function (e) {
                formOnePreview.innerText = "Что-то пошло не так";
            }
            reader.readAsDataURL(file);
        }

    } catch (e) {
        console.log(e)
    }

    // отправка формы с файлами 2
    try {

        const formTwo = document.getElementById("formTwo");
        const formTwoform = formTwo.querySelector("form");
        formTwoform.addEventListener("submit", formTwoSend);

        async function formTwoSend(event) {
            event.preventDefault();

            console.log(event);

            const formData = new FormData(event.target);
            const data = Object.fromEntries(formData);
            const title = formTwo.querySelector("h2").innerText;
            const fullData = {...data, title, dropzone: formTwoImage.files[0]}
            //console.log(fullData);
            //formData.append('dropzone', formTwoImage.files[0])
            // const formData = new FormData();
            // droppedFiles.forEach((item) => {
            //     formData.append("images", item);
            // });
            formData.append("title", title);

            //console.log(...formData);
            //console.log(fullData);

            let response = await fetch("https://crane-service.ru/wp-content/themes/crane-service/mail-forms.php", {
                method: "POST",
                //headers: {"content-type": "multipart/form-data"},
                //body: new URLSearchParams(fullData).toString()
                body: formData
            })
            if (response.ok) {
                formTwo.classList.add("success");
                formTwoform.reset();
                formTwoPreview.innerText = "";
                setTimeout(() => {
                    formTwo.classList.remove("success");
                }, 3000);
            } else {
                formTwo.classList.add("error");
                setTimeout(() => {
                    formTwo.classList.remove("error");
                }, 3000)
            }
        }

        const formTwoImage = document.getElementById("formTwoImage");
        const formTwoPreview = document.getElementById("formTwoPreview");
        const formTwoMessage = document.getElementById("formTwoMessage");

        formTwoImage.addEventListener("change", () => {
            uploadFile(formTwoImage.files[0]);
        })

        function uploadFile(file) {
            if (!["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
                formTwoMessage.innerText = "Разрешены файлы формата .jpg, .png, .pdf";
                setTimeout(() => {
                    formTwoMessage.innerText = "";
                }, 5000)
                formTwoImage.value = "";
                return;
            }
            if (file.size > 4 * 1024 * 1024) {
                formTwoMessage.innerText = "Файл должен быть менее 4 Мб";
                setTimeout(() => {
                    formTwoMessage.innerText = "";
                }, 5000);
                formTwoImage.value = "";
                return;
            }

            let reader = new FileReader();
            reader.onload = function (e) {
                //formTwoPreview.innerText = file.name;
                //formTwoPreview.innerHTML = `<img src="${e.target.result}" alt="foto">`;
            }
            reader.onerror = function (e) {
                formTwoPreview.innerText = "Что-то пошло не так";
            }
            reader.readAsDataURL(file);
        }

    } catch (e) {
        console.log(e)
    }


    // scroll to top
    try {
        const scrollToTop = document.querySelector("#scroll-to-top");
        scrollToTop.addEventListener("click", (e) => {
            e.preventDefault();
            window.scroll({
                top: 0,
                behavior: "smooth",
            })
        })

    } catch (e) {
        console.log(e)
    }

    //scroll element

    try {

        const visible = (target) => {
            // Все позиции элемента
            const targetPosition = {
                    top: window.pageYOffset + target.getBoundingClientRect().top,
                    left: window.pageXOffset + target.getBoundingClientRect().left,
                    right: window.pageXOffset + target.getBoundingClientRect().right,
                    bottom: window.pageYOffset + target.getBoundingClientRect().bottom
                },
                // Получаем позиции окна
                windowPosition = {
                    top: window.pageYOffset,
                    left: window.pageXOffset,
                    right: window.pageXOffset + document.documentElement.clientWidth,
                    bottom: window.pageYOffset + document.documentElement.clientHeight
                };

            if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
                targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
                targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
                targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
                // Если элемент полностью видно, то запускаем следующий код
                //console.clear();
                return true;
            } else {
                //console.clear();
                return false;
            }
        };

        const services = document.querySelector("#services");
        const icon1 = services.querySelector(".icon1");
        const icon2 = services.querySelector(".icon2");


        window.addEventListener("scroll", () => {
            let scrollPosition = window.scrollY;

            if (visible(icon1)) {
                icon1.style.top = `${0 + (scrollPosition * 0.2)}px`;
            }
            if (visible(icon2)) {
                icon2.style.bottom = `${0 + (scrollPosition * 0.3)}px`;
            }

        })

    } catch (e) {
        console.log(e)
    }

    //tabs
    try {
        const tabMenuItems = document.querySelectorAll(".tabmenu__item");
        const tabContentItems = document.querySelectorAll(".tabcontent__block");

        tabMenuItems.forEach((item) => {
            item.addEventListener("click", () => {
                tabMenuItems.forEach((i) => {
                    i.classList.remove("active");
                })
                item.classList.add("active");
                tabContentItems.forEach((i) => {
                    i.classList.remove("active");
                })
                document.querySelector(`#${item.getAttribute("data-id")}`).classList.add("active");
            })
        })


    } catch (e) {
        console.log(e);
    }

    //tabs params
    try {
        const paramsTabItems = document.querySelectorAll(".paramsTab__item");
        const paramsContentItem = document.querySelectorAll(".paramsContent__item");

        paramsTabItems.forEach((item) => {
            item.addEventListener("click", () => {
                paramsTabItems.forEach((i) => {
                    i.classList.remove("active");
                })
                item.classList.add("active");
                paramsContentItem.forEach((i) => {
                    i.classList.remove("active");
                })
                document.querySelector(`#${item.getAttribute("data-id")}`).classList.add("active");
            })
        })

    } catch (e) {
        console.log(e)
    }


    try {
        //mask phone
        const telSelector = document.querySelectorAll('input[type="tel"]');
        const inputMask = new Inputmask({"mask": "+7 (999) 999-99-99", showMaskOnHover: false});
        inputMask.mask(telSelector);
    } catch (e) {
        console.log(e)
    }

    // try {
    //     $(function () {
    //         var myFileUploadDropZone = new Dropzone(".form__dropzone", {
    //             url: "your-upload-file",
    //             maxFiles: 15,
    //             maxFilesize: 5,
    //             acceptedFiles: ".png, .jpg, .gif, .pdf, .doc",
    //             addRemoveLinks: true,
    //             dictDefaultMessage: "Drop your files here or click to upload",
    //             dictFallbackMessage: "Your browser does not support drag & drop feature.",
    //             dictInvalidFileType: "Your uploaded file type is not supported.",
    //             dictFileTooBig: "File is too big ({{filesize}} MB). Max filesize: {{maxFilesize}} MB.",
    //             dictResponseError: "Server responded with {{statusCode}} code.",
    //             dictCancelUpload: "Cancel Upload",
    //             dictRemoveFile: "Remove",
    //             init: function () {
    //                 this.on("complete", function (file) {
    //                     this.removeFile(file);
    //                 });
    //             }
    //         });
    //     });
    // } catch (e) {
    //     console.log(e)
    // }


    //faq
    try {

        const faq_questions = document.querySelectorAll("#faq .item__block_question");

        faq_questions.forEach((item) => {
            item.addEventListener("click", () => {

                faq_questions.forEach((i) => {
                    i.classList.remove("active");
                    i.nextElementSibling.classList.remove("show");
                })

                item.classList.add("active");
                item.nextElementSibling.classList.add("show");
            })
        })

    } catch (e) {
        console.log(e);
    }


});


